import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export const PackagesNew = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <>
      <Helmet>
        <title>{"Packages"}</title>

        <link rel="canonical" href={`https://travco.com/${language}/ibe`} />
        <meta name="description" content={""} />
        <meta property="og:title" content={""} />
        <meta property="og:description" content={""} />
        <meta name="twitter:title" content={""} />
        <meta name="twitter:description" content={""} />
        <link rel="stylesheet" href="css/news.css" />
        <style type="text/css">{`@media screen and  (max-width: 768px) {
    .rlr-product-filters__filters-wrapper .rlr-checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
    }
        
}header{
 margin-bottom:70px;}
    .navigation.fixed-top{
        height: 70px;
            background-color: rgba(0, 0, 0, 0.8);
           
        }`}</style>
        <script
          src="https://ibe.travco.com/integration/v1/travcoegypt/embed.js"
          type="text/javascript"
        ></script>
      </Helmet>{" "}
      {/* <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>

                  <img
                    src={`https://cp.travco.com/images/uploads/banner-travel-packages.jpg`}
                    alt={"Travel Packages Banner"}
                    loading="lazy"
                  />
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {"Packages"}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>{" "} */}
      <div id="nz-planner" onload="nz.init()"></div>
    </>
  );
};
