import React, { Suspense, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { fetchHomePageData } from "../data/homeSlice";
import BookingWidget from "../components/BookingWidget";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Home = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const currency = useSelector((state) => state.currency.currency);
  const { data, loading, error } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(fetchHomePageData({ language, currency }));
  }, [dispatch, language, currency]);

  const [isOpen, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const bannerSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: "0",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: (
      <div class="slick-arrow">
        <div class="arrow next" />
      </div>
    ),
    prevArrow: (
      <div class="slick-arrow">
        <div class="arrow prev" />
      </div>
    ),
  };

  return (
    // <>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <section
    //       style={{ backgroundColor: "#e60000", padding: "200px 0 100px" }}
    //     >
    //       <h1 className="text-center text-light">{error}</h1>
    //     </section>
    //   ) : (
    <>
      <Helmet>
        <title>
          {data.pageDetails?.pageMetatagTitle ||
            "Travco Travel Company of Egypt"}
        </title>
        <link rel="canonical" href={`https://travco.com/${language}`} />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={data.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={data.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={data.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={data.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={data.pageDetails?.metatagDescriptionTwitter}
        />
        <link href="css/hotels.css" rel="stylesheet" type="text/css" />
        <link
          href="TITSolutions/WS/assets/hotel-form.css"
          rel="stylesheet"
          type="text/css"
        />
        <style>{`.bg-text:after{
        content:"";    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50px 0 50px 0;}`}</style>
      </Helmet>
      {/*Banner */}
      <section className="main_slider js_height home">
        <Slider
          {...bannerSetting}
          className="slider_wrap"
          id="main_slider_wrap"
        >
          {data.sliders?.map((slide, index) => (
            <div className="slide" key={index}>
              <div
                className="bg-img"
                style={{
                  height: "100vh",
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1) ),url(${slide.sliderPhoto}) center / cover no-repeat`,
                }}
              ></div>
              <div className="container">
                <div className="wrap_float js_height">
                  <div className="slide_content">
                    <div className="title_wrap">
                      <h2
                        className="slide_title"
                        style={{ textShadow: "1px 1px 2px #000" }}
                      >
                        {slide.sliderMainText}
                      </h2>
                    </div>
                    <p
                      className="text text-light"
                      style={{ textShadow: "1px 1px 2px #000" }}
                      dangerouslySetInnerHTML={{
                        __html: `${slide.sliderSubText}`,
                      }}
                    />
                    {slide.sliderButtonText && (
                      <div className="buttons">
                        <Link
                          to={`${slide.sliderbuttonUrl}`}
                          className="btn button text-light"
                        >
                          {slide.sliderButtonText}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
      <BookingWidget />
      <section className={`${isMobile ? "" : "pt-250"} `}>
        <div style={{ background: `url(images/uploads/contact-bg-1.png)` }}>
          <div
            class="container "
            style={{
              height: "500px",
              position: "relative",
            }}
          >
            <div
              class="bg-image"
              style={{
                // background: `url(Sphinx-and-pyramids.jpg)`,
                background: `url(Sphinx-and-pyramids.jpg)`,
                backgroundPosition: "center",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                filter: "blur(0px)",
                backgroundAttachment: "fixed",
                borderRadius: " 50px 0 50px 0",
              }}
            ></div>

            <div
              class="bg-text  border-0 p-5"
              style={{
                borderRadius: "20px",
                backgroundColor: " rgb(0, 0, 0,0.68);",
                boxShadow: "0 0 10px #fff",
                backgroundImage: `url(Sphinx-and-pyramids.jpg)`,

                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                filter: "blur(0px)",
                backgroundAttachment: "fixed",
                borderRadius: " 50px 0 50px 0",
                width: "50%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 className="rlr-section__title--main text-light mb-5">
                {t("Cairo Splendor & Nile Majesty")}
              </h2>

              <p className="rlr-section_title--sub fw-light mb-5">
                {t(
                  "A Journey Through Time—Join Our Exclusive Group Departures"
                )}
              </p>

              <Button
                className="px-5"
                onClick={() =>
                  navigate(
                    "/packages/cairo-splendor-nile-majesty-a-journey-through-time"
                  )
                }
              >
                {t("Explore Now")}
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/*Tours */}
      <section className={`${isMobile ? "pt-5" : "pt-5"} pb-5`} id="tours">
        <div className="container">
          {" "}
          <div className="rlr-section-header">
            <div className="rlr-section__title">
              <h2 className="rlr-section__title--main">
                {data.packages?.sec1Title}
              </h2>
              <span
                className="rlr-section_title--sub"
                dangerouslySetInnerHTML={{
                  __html: `${data.packages?.sec1SubTitle}`,
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="button-row ">
                {" "}
                <button
                  type="button"
                  className="btn rlr-button button tour-button-previous rlr-button--carousel d-inline-block"
                  aria-label="Previous"
                >
                  <i className="rlr-icon-font flaticon-left-chevron"> </i>
                </button>
                <div className="button-group button-group--cells ">
                  {data.packages?.packagesList?.map((tour, index) => (
                    <button
                      key={index}
                      className={`button ${index === 0 ? "is-selected" : ""}`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <button
                  type="button"
                  className="btn rlr-button button tour-button-next rlr-button--carousel d-inline-block"
                  aria-label="Next"
                >
                  <i className="rlr-icon-font flaticon-chevron"> </i>
                </button>
              </div>

              {/*  <Link to="#." className="link mt-3 destinations-link">
                <i className="icon  rlr-icon-font flaticon-parachute   text-travco mr-2"></i>
                View All Tours
              </Link> */}
            </div>
          </div>
          <div className="rlr-carousel__items">
            <Suspense fallback={<div>{t("Loading")}...</div>}>
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                navigation={{
                  nextEl: ".tour-button-next",
                  prevEl: ".tour-button-previous",
                }}
                breakpoints={{
                  // when window width is >= 320px (mobile screens)
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 768px (tablet screens)
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  // when window width is >= 1024px (desktop screens)
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                // autoplay={{ delay: 5000 }}
                // speed={1000}
                loop={true}
              >
                <div className="swiper-wrapper">
                  {data.packages?.packagesList?.map((tour, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                      <article className="rlr-product-card rlr-product-card--v3">
                        <figure className="rlr-product-card__image-wrapper">
                          <span className="rlr-badge rlr-badge-- rlr-badge--accent-red rlr-product-card__badge">
                            {tour.destinationName}
                          </span>
                          <img
                            src={tour.productPhoto}
                            className="lazyload"
                            alt={tour.productName}
                            loading="lazy"
                          />
                        </figure>
                        <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                          <div className="rlr-product-card__details d-flex justify-content-start align-items-center">
                            <div
                              className="rlr-product-card__prices"
                              itemProp="offers"
                              itemScope=""
                            >
                              <span className="rlr-product-card__price">
                                <mark itemProp="priceCurrency"></mark>
                                <mark itemProp="price"> </mark>
                              </span>
                            </div>
                            <div className="rlr-product-card__footer">
                              <div className="rlr-icon-text rlr-product-card__icon-text ">
                                <i className="rlr-icon-font flaticon-three-o-clock-clock days"></i>{" "}
                                <span className="text-light days">
                                  {tour.durationTypeNumber}{" "}
                                  {tour.durationTypeSys} |
                                </span>
                                <span>
                                  {t("Starting From")} {tour.priceCurrency}
                                  {tour.priceDouble}
                                </span>
                              </div>
                            </div>
                          </div>
                          <header className="rlr-product-card__header">
                            <div>
                              <Link
                                className="rlr-product-card__anchor-title"
                                to={`/packages/${tour.productUrl}`}
                              >
                                <h2
                                  className="rlr-product-card__title"
                                  itemProp="name"
                                >
                                  {tour.productName}
                                </h2>
                              </Link>
                            </div>
                          </header>
                        </div>
                      </article>
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </Suspense>
          </div>
        </div>
      </section>
      {/*Destinations */}
      <section className="my-5 py-5" id="Destinations">
        <div className="container">
          <div className="rlr-section-header pb-5" style={{ paddingBottom: 0 }}>
            <div className="rlr-section__title">
              <h2 className="rlr-section__title--main">
                {data.destinations?.destinationHeader}
              </h2>
              <span
                className="rlr-section_title--sub"
                dangerouslySetInnerHTML={{
                  __html: `${data.destinations?.destinationSubTitle}`,
                }}
              />
            </div>
            <div className="stories py-0">
              <div className="wrap">
                <div className="wrap_float">
                  <div className="button-row destinations-btns d-flex flex-column align-items-center justify-content-center">
                    <div className="arrows">
                      <button
                        type="button"
                        className="btn rlr-button button dest-button-previous rlr-button--carousel d-inline-block"
                        aria-label="Previous"
                      >
                        <i className="rlr-icon-font flaticon-left-chevron"> </i>
                      </button>
                      <div className="button-group button-group--cells">
                        <button className="button is-selected">1</button>
                        <button className="button">2</button>
                      </div>
                      <button
                        type="button"
                        className="btn rlr-button button dest-button-next rlr-button--carousel d-inline-block"
                        aria-label="Next"
                      >
                        <i className="rlr-icon-font flaticon-chevron"> </i>
                      </button>
                    </div>
                    <Link
                      to={`destination/egypt`}
                      className="link mt-3 destinations-link"
                    >
                      {/* <i className="icon  rlr-icon-font flaticon-destination-1 text-travco mr-2"></i>{" "} */}
                      {data.destinations?.destinationButtonText}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rlr-carousel__items my-5">
            <Suspense fallback={<div>{t("Loading")}...</div>}>
              <Swiper
                spaceBetween={180}
                slidesPerView={5}
                navigation={{
                  nextEl: ".dest-button-next",
                  prevEl: ".dest-button-previous",
                }}
                pagination={false}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                speed={3000}
                loop={true}
                breakpoints={{
                  // when window width is >= 320px (mobile screens)
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 768px (tablet screens)
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  // when window width is >= 1024px (desktop screens)
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 180,
                  },
                }}
              >
                <div className="stories py-0 mt-5">
                  <div className="wrap">
                    <div className="wrap_float">
                      <div className="section_content">
                        <div className="stries_slider" id="dist_slider">
                          {data.destinations?.destinationsList?.map(
                            (destination, index) => (
                              <SwiperSlide key={index}>
                                <Link
                                  to={`/destination/egypt/${destination.destinationUrl}`}
                                  className="story_item"
                                  style={{
                                    backgroundImage: `url(${destination.destinationMainPhoto})`,
                                  }}
                                >
                                  <div className="item_wrap">
                                    <div className="_content">
                                      <h3 className="country">
                                        {destination.destinationNameSys}
                                      </h3>
                                      <p className="text">
                                        {destination.productCount} {t("Tours")}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="shadow js-shadow"
                                    style={{
                                      backgroundImage: `url(${destination.destinationMainPhoto})`,
                                    }}
                                  ></div>
                                </Link>
                              </SwiperSlide>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Swiper>
            </Suspense>
          </div>
        </div>
      </section>
      {/*Stories */}
      <section className="my-5 py-5" id="Stories">
        <div className="container">
          {" "}
          <div className="rlr-section-header pb-5" style={{ paddingBottom: 0 }}>
            <div className="rlr-section__title">
              <h2 className="rlr-section__title--main">
                {data.productStyles?.travelIdeaTitle}
              </h2>
              <span className="rlr-section_title--sub">
                {data.productStyles?.travelIdeaSubTitle}
              </span>
            </div>
            <div className="stories py-0">
              <div className="wrap">
                <div className="wrap_float">
                  <div className="button-row stories-btns d-flex flex-column align-items-center justify-content-center">
                    <div className="arrows">
                      <button
                        type="button"
                        className="btn rlr-button button button--previous rlr-button--carousel story-button-prev"
                        aria-label="Previous"
                      >
                        <i className="rlr-icon-font flaticon-left-chevron"> </i>
                      </button>
                      <div className="button-group button-group--cells">
                        {data.productStyles?.productStylesList?.map(
                          (story, index) => (
                            <button
                              key={index}
                              className={`button ${
                                index === 0 ? "is-selected" : ""
                              }`}
                            >
                              {index + 1}
                            </button>
                          )
                        )}
                      </div>
                      <button
                        type="button"
                        className="btn rlr-button button button--next rlr-button--carousel story-button-next"
                        aria-label="Next"
                      >
                        <i className="rlr-icon-font flaticon-chevron"> </i>
                      </button>
                    </div>
                    <Link
                      to="/travel-idea"
                      className="link mt-3 destinations-link"
                    >
                      {/* <FontAwesomeIcon
                        className="text-travco mr-2"
                        icon={faStar}
                      /> */}
                      {t("View All Types")}
                    </Link>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="rlr-carousel__items my-5">
            <Suspense fallback={<div>{t("Loading")}...</div>}>
              {" "}
              <Swiper
                spaceBetween={50}
                slidesPerView={5}
                navigation={{
                  nextEl: ".story-button-next",
                  prevEl: ".story-button-prev",
                }}
                autoplay={false}
                freeMode={false}
                loop={true}
                speed={1000} // Transition speed (duration of the transition in ms)
                breakpoints={{
                  // when window width is >= 320px (mobile screens)
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 768px (tablet screens)
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  // when window width is >= 1024px (desktop screens)
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 180,
                  },
                }}
              >
                <div className="stories py-0 mt-5">
                  <div className="wrap">
                    <div className="wrap_float">
                      <div className="section_content">
                        <div className="stries_slider" id="stries_slider">
                          {data.productStyles?.productStylesList?.map(
                            (story, index) => (
                              <SwiperSlide key={index}>
                                <Link
                                  to={`/travel-idea/${story.productFullUrl}`}
                                  className="story_item"
                                  style={{
                                    backgroundImage: `url(${story.productStylePhoto})`,
                                  }}
                                >
                                  <div className="item_wrap">
                                    <div className="_content">
                                      <h3 className="country">
                                        {story.productStyleNameSys}
                                      </h3>
                                      <p className="text">
                                        {story.productCount} {t("Tours")}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    className="shadow js-shadow"
                                    style={{
                                      backgroundImage: `url(${story.productStylePhoto})`,
                                    }}
                                  ></div>
                                </Link>
                              </SwiperSlide>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Swiper>
            </Suspense>
          </div>
        </div>
      </section>{" "}
      {/*Partners */}
      <section
        className="trigger section gutter-horizontal bg-gray gutter-vertical--m gutter-horizontal container my-5"
        id="partners"
      >
        <div className="customer-logos slider">
          <Suspense fallback={<div>{t("Loading")}...</div>}>
            <Swiper
              spaceBetween={0}
              slidesPerView={4}
              navigation
              autoplay={{ delay: 2500, disableOnInteraction: false }} // Autoplay configuration
              speed={800} // Transition speed (duration of the transition in ms)
              loop={true} // Enable infinite loop
              breakpoints={{
                // when window width is >= 320px (mobile screens)
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                // when window width is >= 768px (tablet screens)
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // when window width is >= 1024px (desktop screens)
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
              }}
            >
              {data.partners?.map((partner, index) => (
                <SwiperSlide
                  key={index}
                  className="slide-in-right slide text-center mx-auto"
                >
                  <img
                    src={partner.partnerLogo}
                    alt={partner.partnerName}
                    height="150"
                    width="150"
                    loading="lazy"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Suspense>
        </div>
      </section>
    </>
    //   )}
    // </>
  );
};
export default Home;
